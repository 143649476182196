import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBpWZor3gPgFdc2KP8CWzZiD3cN52JFR0I',
  authDomain: 'cooking-ninja-site-e6bf5.firebaseapp.com',
  projectId: 'cooking-ninja-site-e6bf5',
  storageBucket: 'cooking-ninja-site-e6bf5.appspot.com',
  messagingSenderId: '644250935662',
  appId: '1:644250935662:web:80b96595ad90e01b60a17f'
}

//init firebase

firebase.initializeApp(firebaseConfig)

//init services
const projectFirestore = firebase.firestore()

export { projectFirestore }
